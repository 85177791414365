.Intro {
    .home_hero {
        h1 {
            line-height: 1;
            span {
                font-size: 1.5rem;
            }
        }
        img {
            max-width: 10rem;
          }
    }
    .social-icons {
        margin-top: 1.5rem;
        
        a {
            color: var(--secon);
            margin-right: 10px;
            transition: 0.3s;
            will-change: translate;
            display: inline-block;
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                transform: translateY(-5px);
                color: var(--secon);
            }
        }
    }
}