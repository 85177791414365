@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;800&display=swap');

:root {
    --prime: #e1ff00;
    --secon: #343a40;
    --ltgray: #F5F5F5;
  }

  * {
        font-family: 'Montserrat', sans-serif !important;
    }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
//   code {
//     font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//       monospace;
//   }

  h1, h2, h3, h4, h5, h6, p, li, a {
    margin: 0 0 10px 0;
}

h1 {
  font-weight: bold;
  font-size: 44px;
}
h2 {
  font-weight: lighter;
  font-size: 40px;
  @media(max-width: 48rem) {
    font-size: 26px;
  }
}
h3 {
  font-weight: lighter;
  font-size: 36px;
}
p, li {
    font-size: 16px;
    line-height: 2;
}
a {
  transition: 0.3s;
  will-change: transform;
  display: inline-block;
  text-decoration: none;
  margin: initial;
  &:hover {
    transform: translateY(-5px);
    text-decoration: none;
  }
}
.uk-offcanvas-bar {
  background: var(--prime);
  box-shadow: 0 0 30px -10px #000;
}
.uk-offcanvas-overlay::before {
  background: rgba(0,0,0,0.7);
}

.list-section {
	margin: 100px;
}
@media (max-width: 763px) {
	.list-section {
		margin: 100px 0;
	}
}