.Button {
    padding: 10px 50px;
    display: inline-block;
    border-radius: 25px;
    min-width: 100px;
    font-weight: bold;
    text-transform: uppercase;
    transition: 0.3s;
    text-decoration: none;
    &__primary {
        background: var(--secon);
        color: white ;
        &:hover {
            opacity: 0.8;
        }
    }
    &__secon {
        background: var(--prime);
        color: var(--secon) ;
        &:hover {
            opacity: 0.8;
        }
    }
}