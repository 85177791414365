.App {
  p,
  li {
    font-size: 16px;
    line-height: 2;
  }
  img {
    max-width: 100px;
    margin-bottom: 20px;
  }
  .startup {
    margin: 100px;
  }
  a {
    cursor: pointer;
  }
}

@media (max-width: 763px) {
  .App {
    .startup {
      margin: 100px 0;
    }
  }
}
