.Footer {
    background: var(--secon);
    text-align: center;
    padding: 10px 0;
    p {
        margin: 0;
        color: white;
        font-size: 14px;
    }
    a {
        font-weight: bold;
        color: var(--prime);
    }
    svg {
        color: var(--prime);
    }
}