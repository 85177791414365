.Section {
    // min-height: 100vh;
    position: relative;
    .section_content {
        max-width: 60%;
        @media(max-width: 767px) {
            max-width: 90%;
        }
        padding: 150px 0;
        @media(max-width: 767px) {
            padding: 100px 0;
        }
        text-align: center;
        margin: 0 auto;
        
        h1,h2,h3,h4,h5,h6,p,a {
            color: var(--prime);
        }
        a {
            font-weight: bold;
            color: var(--secon);
        }
    }
    &__primary {
        background: var(--white);
        color: var(--secon);
        .section_content {
            h1,h2,h3,h4,h5,h6,p,a {
                color: var(--secon);
            }
            a {
                color: var(--prime);
            }
        }
    }
    &__secondary {
        background: var(--prime);
        color: var(--secon);
        .section_content {
            h1,h2,h3,h4,h5,h6,p,a {
                color: var(--secon);
            }
            a {
                color: var(--prime);
            }
        }
    }
    &__tertiary {
        background: var(--secon);
        color: white;
        .section_content {
            h1,h2,h3,h4,h5,h6,p,a {
                color: white;
            }
            a {
                color: var(--prime);
            }
        }
    }
}