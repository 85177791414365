@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;800&display=swap);
:root{--prime: #e1ff00;--secon: #343a40;--ltgray: #F5F5F5}*{font-family:"Montserrat",sans-serif !important}body{margin:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}h1,h2,h3,h4,h5,h6,p,li,a{margin:0 0 10px 0}h1{font-weight:bold;font-size:44px}h2{font-weight:lighter;font-size:40px}@media(max-width: 48rem){h2{font-size:26px}}h3{font-weight:lighter;font-size:36px}p,li{font-size:16px;line-height:2}a{transition:.3s;will-change:transform;display:inline-block;text-decoration:none;margin:0;margin:initial}a:hover{transform:translateY(-5px);text-decoration:none}.uk-offcanvas-bar{background:#e1ff00;background:var(--prime);box-shadow:0 0 30px -10px #000}.uk-offcanvas-overlay::before{background:rgba(0,0,0,.7)}.list-section{margin:100px}@media(max-width: 763px){.list-section{margin:100px 0}}
.Footer{background:var(--secon);text-align:center;padding:10px 0}.Footer p{margin:0;color:#fff;font-size:14px}.Footer a{font-weight:bold;color:var(--prime)}.Footer svg{color:var(--prime)}
.Section{position:relative}.Section .section_content{max-width:60%;padding:150px 0;text-align:center;margin:0 auto}@media(max-width: 767px){.Section .section_content{max-width:90%}}@media(max-width: 767px){.Section .section_content{padding:100px 0}}.Section .section_content h1,.Section .section_content h2,.Section .section_content h3,.Section .section_content h4,.Section .section_content h5,.Section .section_content h6,.Section .section_content p,.Section .section_content a{color:var(--prime)}.Section .section_content a{font-weight:bold;color:var(--secon)}.Section__primary{background:var(--white);color:var(--secon)}.Section__primary .section_content h1,.Section__primary .section_content h2,.Section__primary .section_content h3,.Section__primary .section_content h4,.Section__primary .section_content h5,.Section__primary .section_content h6,.Section__primary .section_content p,.Section__primary .section_content a{color:var(--secon)}.Section__primary .section_content a{color:var(--prime)}.Section__secondary{background:var(--prime);color:var(--secon)}.Section__secondary .section_content h1,.Section__secondary .section_content h2,.Section__secondary .section_content h3,.Section__secondary .section_content h4,.Section__secondary .section_content h5,.Section__secondary .section_content h6,.Section__secondary .section_content p,.Section__secondary .section_content a{color:var(--secon)}.Section__secondary .section_content a{color:var(--prime)}.Section__tertiary{background:var(--secon);color:#fff}.Section__tertiary .section_content h1,.Section__tertiary .section_content h2,.Section__tertiary .section_content h3,.Section__tertiary .section_content h4,.Section__tertiary .section_content h5,.Section__tertiary .section_content h6,.Section__tertiary .section_content p,.Section__tertiary .section_content a{color:#fff}.Section__tertiary .section_content a{color:var(--prime)}
.Company>a{margin-top:1rem}
.Button{padding:10px 50px;display:inline-block;border-radius:25px;min-width:100px;font-weight:bold;text-transform:uppercase;transition:.3s;text-decoration:none}.Button__primary{background:var(--secon);color:#fff}.Button__primary:hover{opacity:.8}.Button__secon{background:var(--prime);color:var(--secon)}.Button__secon:hover{opacity:.8}
.Header{background:rgba(0,0,0,0);padding:10px 0;position:fixed;z-index:9;width:100%}.Header img{max-height:50px;-webkit-filter:drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));filter:drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));margin:0;cursor:pointer}.Header .mobile_nav_content{margin-top:50px}.Header .mobile_nav_content a{display:block;color:var(--secon);cursor:pointer}.Header .mobile_nav_content a li{padding:20px 0;text-align:center;font-weight:bold;font-size:1.5rem}.Header .nav{line-height:50px;text-align:right}.Header .nav button{margin:0;cursor:pointer;border:0;background:rgba(0,0,0,0);color:var(--secon);border-radius:4px}.Header .nav button svg{cursor:pointer}.Header .nav button svg path{stroke:var(--prime);stroke-width:28px}
.Intro .home_hero h1{line-height:1}.Intro .home_hero h1 span{font-size:1.5rem}.Intro .home_hero img{max-width:10rem}.Intro .social-icons{margin-top:1.5rem}.Intro .social-icons a{color:var(--secon);margin-right:10px;transition:.3s;will-change:translate;display:inline-block}.Intro .social-icons a:last-child{margin-right:0}.Intro .social-icons a:hover{transform:translateY(-5px);color:var(--secon)}

.App p,.App li{font-size:16px;line-height:2}.App img{max-width:100px;margin-bottom:20px}.App .startup{margin:100px}.App a{cursor:pointer}@media(max-width: 763px){.App .startup{margin:100px 0}}


